@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  * {
    @apply select-none focus:outline-none;
  }
}

/* .set-title[title]:hover::after {
  content: attr(title);
  position: absolute;
  top: -100%;
  left: 0;
  background-color: gainsboro;
  color: white;
} */

/* a {
  position: relative;
 
} */

/* a[title]:hover::after {
  content: attr(title);
  position: absolute;
  width: 150px;
  top: 70%;
  left: 100%;
  background-color: gainsboro;
  color: white;
} */
